.listContainer {
  @apply relative;

  &::before {
    @apply w-1 h-full bg-display-0 absolute left-20;
    @apply lg:h-1 lg:w-full lg:left-0 lg:top-20;
    content: '';
  }

  & li {
    @apply z-10;

    & > div:first-child {
      @apply lg:px-16 bg-display-800;
    }

    @screen lg {
      &:first-child {
        & > div:first-child {
          @apply pl-[50%] self-start;
          padding-left: calc(50% - 16px);
        }
      }

      &:last-child {
        & > div:first-child {
          @apply pr-[50%] self-end;
          padding-right: calc(50% - 16px);
        }
      }
    }

    &:last-child {
      @apply bg-display-800 lg:bg-transparent;
    }
  }
}
